import StatsBarGraph from "components/stats/mini-components/StatsBarGraph";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import StatsLineGraph from "./mini-components/StatsLineGraph";


export const UserDailyGraph = forwardRef((props: { data: any[], color: string }, ref) => {
	const [isLineGraph, setIsLineGraph] = useState<boolean>(true)

	useImperativeHandle(
		ref,
		() => ({
			setLineGraph() {
				setIsLineGraph(true)
			},
			setBarGraph() {
				setIsLineGraph(false)
			},
		}),
	)

	if (isLineGraph)
		return (
			< StatsLineGraph data={props.data} color={props.color} dataKey={'total'} />);
	else
		return (
			<StatsBarGraph data={props.data} color={props.color} dataKey={'total'} />
		)
})
