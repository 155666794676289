import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "services/firebase";

/**
 * This class is controller for get stats from firestore
 */
export default class StatsController {

	/**
	 * Reference for user stats document
	 */
	usersStatsRef = doc(db, `stats/users`)

	// /**
	//  * Reference for goals stats documnet
	//  */
	goalsStatsRef = doc(db, `stats/goals`)

	// /**
	//  * Reference for investment stats document
	//  */
	investmentStatsRef = doc(db, `stats/investment`)

	/**
	 * Reference for user stats document
	 */
	DailyDepositsStatsRef = doc(db, `stats/users`)

	/**
	 * @param observer Handle changes on changes
	 * @returns Bsic stats of users (totals)
	 */
	getUserStatsData = (observer: any) => {
		onSnapshot(this.usersStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		);
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Total user stats for graph
	 */
	async getUserTotalStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/total/${docId}`));
		return snapshot.data() ?? {}
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Kyc verified user stats for graph
	 */
	async getUserKycVerificationGraphData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/kyc/${docId}`));
		return snapshot.data() ?? {}
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Kyc verified user stats for graph
	 */
	async getUserLendboxGraphData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/lendbox/${docId}`));
		return snapshot.data() ?? {}
	}

	/**
	 * @param month Month for which data is to be fetched
	 * @param year Year for which data is to be fetched
	 * @returns Kyc verified user stats for graph
	 */
	async getUserActiveStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/active/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserOnboardedStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/onboarded/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserContactStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/contact/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserMandateExecutionsStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/yesbankMandateExecution/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserMandateExecutionsAmountStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/yesbankMandateExecutionAmount/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserLongKycStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/longKyc/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUsershortKycStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/shortKyc/${docId}`));
		return snapshot.data() ?? {}
	}
	async getUserPausedStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/paused/${docId}`));
		return snapshot.data() ?? {}
	}
	async getUserDailyDepositStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/dailyDeposit/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserRoundUpStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/roundup/${docId}`));
		return snapshot.data() ?? {}
	}

	async getUserYesbankMandateStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/yesbankMandate/${docId}`));
		return snapshot.data() ?? {}
	}
	async getUsers100StepStatsData(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/users/mandates/data/rs100Step/${docId}`));
		return snapshot.data() ?? {}
	}
	/**
	 * @param observer Handle changes on changes
	 * @returns Goal stats (Category wise and totals)
	 */
	getGoalsStatsData(observer: any) {
		onSnapshot(this.goalsStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		);
	}

	/**
	 * @param observer Handle changes on changes
	 * @returns Investment stats (Wallet, Invested and total)
	 */
	getInvestmentStatsData(observer: any) {
		onSnapshot(this.investmentStatsRef, (snapshot: any) => {
			observer(snapshot.data())
		}
		);
	}
	/**
	 * @param observer Handle changes on changes
	 * @returns Investment stats (Wallet, Invested and total)
	 */
	async getInvestmentGraph(month: string, year: string) {
		var docId = year + month;
		var snapshot = await getDoc(doc(db, `stats/investment/data/${docId}`));
		return snapshot.data() ?? {}
	}
}