import { query, where, collection, onSnapshot } from "firebase/firestore";
import { db } from "services/firebase";

/**
 * Interface for UserData
 */
export interface UserData {
	uid: string,
	phone: string,
	firstName?: string,
	lastName?: string,
	roundup?: number,
	recurring?: number,
	lendboxBalance?: number,
	lendboxAvailableToWithdraw?: number,
	lendboxAmounWithdrawn?: number,
	lendboxInProgress?: number,
	lendboxTotalSaved?: number,
	lendboxWithdrawalInProgress?: number,
	totalRewardEarned?: number,
	isAdmin?: boolean,
	onboardingIciciKycInProgress?: boolean,
	onboardingIcici?: boolean,
	onboardingIciciFirstTime?: boolean,
	onboardingMandate?: boolean,
	onboardingMandateCompleted?: boolean,
	onboardingYesbankMandateCompleted?: boolean,
	onboarding?: any,
	createdAt?: any
}

/**
 * This class is controller for interfacting withs users from firestore
 */
export default class UsersController {
	processSnapshot(snapshot: any) {
		var usersList: UserData[] = snapshot.docs.map((doc: any) => {
			var data = doc.data()
			data.id = doc.id
			if (data.recurring == null) {
				data.recurring = 0
			}
			if (data.roundup == null) {
				data.roundup = 0
			}
			if (data.totalRewardEarned == null) {
				data.totalRewardEarned = 0
			}
			if (data.mutualFundBalance == null) {
				data.mutualFundBalance = 0
			}
			if (data.invested == null) {
				data.invested = 0
			}
			if (data.investmentValue == null) {
				data.investmentValue = 0
			}
			if (data.totalSavedSavvy == null) {
				data.totalSavedSavvy = 0
			}
			if (data.totalWithdrawnSavvy == null) {
				data.totalWithdrawnSavvy = 0
			}
			if (data.os == null) {
				data.os = "Unknown"
			} else if (data.os === 'android') {
				data.os = "Android"
			} else if (data.os === 'ios') {
				data.os = "iOS"
			}
			try {
				data.onboardingLendbox = (data?.onboarding['lendbox'])
			} catch (e) {
				data.onboardingLendbox = false
			}
			try {
				data.onboardingIciciKycInProgress = (data?.onboarding['iciciKycInProgress'])
			} catch (e) {
				data.onboardingIciciKycInProgress = false
			}
			try {
				data.onboardingIcici = (data?.onboarding['icici'])
			} catch (e) {
				data.onboardingIcici = false
			}
			try {
				data.onboardingIciciFirstTime = (data?.onboarding['iciciFirstTime'])
			} catch (e) {
				data.onboardingIciciFirstTime = false
			}
			try {
				data.onboardingIciciFirstTimeCompleted = (data?.onboarding['iciciFirstTimeCompleted'])
			} catch (e) {
				data.onboardingIciciFirstTimeCompleted = false
			}
			try {
				data.onboardingMandate = (data?.onboarding['iciciMandate'])
			} catch (e) {
				data.onboardingMandate = false
			}
			try {
				data.onboardingMandateCompleted = (data?.onboarding['iciciMandateCompleted'])
			} catch (e) {
				data.onboardingMandateCompleted = false
			}
			try {
				data.lendboxBalance = (data?.balance['lendbox']['balance'])
			} catch (e) {
				data.lendboxBalance = 0
			}
			try {
				data.lendboxAvailableToWithdraw = (data?.balance['lendbox']['availableToWithdraw'])
			} catch (e) {
				data.lendboxAvailableToWithdraw = 0
			}
			try {
				data.lendboxAmountWithdrawn = (data?.balance['lendbox']['amountWithdrawn'])
			} catch (e) {
				data.lendboxAmountWithdrawn = 0
			}
			try {
				data.lendboxInProgress = (data?.balance['lendbox']['inProgress'])
			} catch (e) {
				data.lendboxInProgress = 0
			}
			try {
				data.lendboxTotalSaved = (data?.balance['lendbox']['totalSaved'])
			} catch (e) {
				data.lendboxTotalSaved = 0
			}
			try {
				data.lendboxWithdrawalInProgress = (data?.balance['lendbox']['withdrawalInProgress'])
			} catch (e) {
				data.lendboxWithdrawalInProgress = 0
			}
			try {
				data.lendboxDailyDeposit = (data?.balance['lendbox']['dailyDeposit'])
			} catch (e) {
				data.lendboxDailyDeposit = 0
			}
			try {
				data.lendboxDailyDepositDays = (data?.balance['lendbox']['dailyDepositDays'])
			} catch (e) {
				data.lendboxDailyDepositDays = 0
			}
			try {
				data.lendboxDirectInvested = (data?.balance['lendbox']['directInvested'])
			} catch (e) {
				data.lendboxDirectInvested = 0
			}
			try {
				data.lendboxInterestPerDay = (data?.balance['lendbox']['interestPerDay'])
			} catch (e) {
				data.lendboxInterestPerDay = 0
			}
			try {
				data.lendboxInvested = (data?.balance['lendbox']['invested'])
			} catch (e) {
				data.lendboxInvested = 0
			}

			try {
				data.lendboxRoundup = (data?.balance['lendbox']['roundup'])
			} catch (e) {
				data.lendboxRoundup = 0
			}
			try {
				data.lendboxRoundupSpends = (data?.balance['lendbox']['roundupSpends'])
			} catch (e) {
				data.lendboxRoundupSpends = 0
			}
			try {
				data.lendboxTotalInterestEarning = (data?.balance['lendbox']['totalInterestEarning'])
			} catch (e) {
				data.lendboxTotalInterestEarning = 0
			}
			try {
				data.lendboxTotalWithdrawal = (data?.balance['lendbox']['totalWithdrawal'])
			} catch (e) {
				data.lendboxTotalWithdrawal = 0
			}
			try {
				data.onboardingYesbankMandateCompleted = (data?.onboarding['yesbankMandateCompleted'])
			} catch (e) {
				data.onboardingYesbankMandateCompleted = false
			}
			if (data.onboardingLendbox) {
				data.onboardingLendbox = 'Completed'
			} else {
				data.onboardingLendbox = 'Not started'
			}
			if (data.onboardingIcici) {
				data.onboardingIcici = 'Completed'
			} else if (data.onboardingIciciKycInProgress ?? false) {
				data.onboardingIcici = 'In Progress'
			} else {
				data.onboardingIcici = 'Not started'
			}
			if (data.onboardingIciciFirstTime) {
				if (data.onboardingIciciFirstTimeCompleted) {
					data.onboardingIciciFirstTime = 'Completed'
				} else {
					data.onboardingIciciFirstTime = 'In Progress'
				}
			} else {
				data.onboardingIciciFirstTime = 'Not started'
			}
			if (data.onboardingYesbankMandateCompleted) {
				data.onboardingMandate = 'Completed'
				data.mandateType = 'UPI'
			}
			else if (data.onboardingMandate) {
				if (data.onboardingMandateCompleted) {
					data.onboardingMandate = 'Completed'
				} else {
					data.onboardingMandate = 'In Progress'
				}
				data.mandateType = 'NACH'
			} else {
				data.onboardingMandate = 'Not started'
				data.mandateType = 'NA'

			}
			if (data.accountPaused === undefined) {
				data.accountPaused = false
			}
			if ((data.roundup ?? 0) === 0 && (data.recurring ?? 0) === 0) {
				data.accountPaused = true
			}
			if (data.createdAt != null) {
				data.createdAt = (new Date(data.createdAt.toDate().getTime() + 330 * 60000)).toISOString()
			}
			if (data.updatedAt != null) {
				data.updatedAt = (new Date(data.updatedAt.toDate().getTime() + 330 * 60000)).toISOString()
			}
			return data
		})
		// Remove test users
		usersList = usersList.filter((value: UserData) => !(['+911111111111', '+918888888888'].includes(value?.phone ?? '')))

		return usersList
	}
	/**
	 * @param observer Handle changes on changes
	 * Get all users in users document except admins
	 */
	getUsers(observer: (userData: UserData[]) => void) {
		onSnapshot(collection(db, `users`), (snapshot: any) => {
			observer(this.processSnapshot(snapshot))
		});
	}

	getUsersByQuery(observer: (userData: UserData[]) => void, queries: any[]) {
		var reference: any = query(collection(db, `users`))
		for (var i in queries) {
			reference = query(reference, where(queries[i].field, queries[i].operator, queries[i].value))
		}
		onSnapshot(reference, (snapshot: any) => {
			observer(this.processSnapshot(snapshot))
		});
	}
}