import { ThemeProvider, } from "@mui/material";
import Dashboard from "components/Dashboard"
import Developer from "components/Developer"
import Goals from "components/Goals"
import Login from "components/Login"
import NotLoggedInRoute from "components/routes/NotLoggedInRoute"
import PrivateRoute from "components/routes/PrivateRoute"
import Users from "components/Users"
import "fontsource-roboto"
import React from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { AuthProvider } from "services/contexts/AuthContext"
import { KeysProvider } from "services/contexts/KeysContext"
import { LayoutProvider } from "services/contexts/LayoutContext"
import KeysView from "./KeysView"
import Transactions from "./transactions/Transactions"
import TransactionsMonth from "./transactions/TransactionsMonth"
import PortfolioMonth from "./portfolio/PortfolioMonth"
import Portfolio from "./portfolio/Portfolio"
import { StyledEngineProvider } from '@mui/material/styles';
import theme from "./theme";
import UsersSearch from "./UsersSearch";
import LendboxView from "./Lendbox";
import MutualFunds from "./MutualFund";

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
				<Router>
					<AuthProvider>
						<KeysProvider>
							<LayoutProvider>
								<Switch>
									<PrivateRoute exact path="/" component={Dashboard} />
									<PrivateRoute exact path="/developer" component={Developer} />
									<PrivateRoute exact path="/keysView/:id" component={KeysView} />
									<PrivateRoute exact path="/users" component={UsersSearch} />
									<PrivateRoute exact path="/users/:uid/goals" component={Goals} />
									<PrivateRoute exact path="/users/:uid/mutualFund" component={MutualFunds} />
									<PrivateRoute exact path="/users/:uid/lendbox" component={LendboxView} />
									<PrivateRoute exact path="/users/:uid/transaction" component={TransactionsMonth} />
									<PrivateRoute exact path="/users/:uid/transaction/:month" component={Transactions} />
									<PrivateRoute exact path="/users/:uid/portfolio" component={PortfolioMonth} />
									<PrivateRoute exact path="/users/:uid/portfolio/:month" component={Portfolio} />
									<PrivateRoute exact path="/usersOld" component={Users} />
									<NotLoggedInRoute path="/login" component={Login} />
								</Switch>
							</LayoutProvider>
						</KeysProvider>
					</AuthProvider>
				</Router>
			</StyledEngineProvider>
		</ThemeProvider >
	)
}