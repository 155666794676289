import { collection, doc, onSnapshot } from "firebase/firestore";
import { getValue } from "firebase/remote-config";
import { db, rc } from "services/firebase";
import getMonth from "services/util/getMonth";
import monthConvert from "services/util/monthConvert";

export interface TransactionMonth {
	id: string,
	monthCode: string,
	month: string,
}
export interface Transaction {
	id: string,
	amount: number,
	total: number,
	type: string,
	status: string,
	txnType: string,
	bankName: string,
	accountType: string
}
function getTransactionType(type: number, category: string, txnCategory: string, merchant: string, details: string): string {
	switch (type) {
		case 0: {
			switch (category) {
				case 'UNIFIED_PAYMENT':
					return 'UPI Transaction';
				case 'IMPS':
					return 'IMPS';
				default: {
					var transactionTypeClassificationString: string = getValue(rc, 'transactionTypeClassification').asString()
					var transactionTypeClassification: any = JSON.parse(transactionTypeClassificationString);
					var priority = Object.keys(transactionTypeClassification)
					priority.sort();
					priority = priority.reverse()
					var ix
					var p
					var px
					var i
					var j
					if (merchant != null) {
						for (p in priority) {
							px = priority[p]
							for (i in Object.keys(transactionTypeClassification[px])) {
								ix = Object.keys(transactionTypeClassification[px])[i]
								for (j in transactionTypeClassification[px][ix]) {
									if (merchant
										?.toLowerCase()
										.includes(transactionTypeClassification[px][ix][j].toLowerCase()) ??
										false) return ix;
								}
							}
						}
					}
					if (details != null)
						for (p in priority) {
							px = priority[p]
							for (i in Object.keys(transactionTypeClassification[px])) {
								ix = Object.keys(transactionTypeClassification[px])[i]
								for (j in transactionTypeClassification[px][ix]) {
									if (details
										?.toLowerCase()
										.includes(transactionTypeClassification[px][ix][j].toLowerCase()) ??
										false) return ix;
								}
							}
						}
					return 'Debit';
				}
			}
		}
		case 1:
			return 'Credit';
		case 2:
			return 'Withdrawal';
		case 3:
			return 'Daily Deposit';
		case 4:
			return 'One Time Investment';
		case 5:
			return 'Mandate Investment';
		case 6:
			return 'First Investment';
		case 7:
			return 'Goal One Time Investment';
		case 8:
			return 'Goal Withdrawal';
		default:
			return 'NA';
	}
}
export default class TransactionController {
	getTransactionMonths(uid: string, observer: (transactionMonths: TransactionMonth[]) => void) {
		onSnapshot(collection(db, `users/${uid}/transactions`), (snapshot: any) => {
			var transactionMonths: TransactionMonth[] = snapshot.docs.map((doc: any) => {
				var data = doc.data()
				data.id = doc.id
				data.monthCode = monthConvert(doc.id)
				data.month = getMonth(data.monthCode)
				return data
			})
			transactionMonths.sort((a: TransactionMonth, b: TransactionMonth) => {
				if (a.monthCode > b.monthCode) return -1
				if (a.monthCode < b.monthCode) return 1
				return 0
			})
			observer(transactionMonths)
		})
	}
	getTransactions(uid: string, month: string, observer: (transactions: Transaction[]) => void) {
		onSnapshot(doc(db, `users/${uid}/transactions/${month}`), (snapshot: any) => {
			var data: any = snapshot.data()
			var transactions: Transaction[] = []
			Object.keys(data).sort().forEach((key: string) => {
				transactions.push({ id: key, type: getTransactionType(data[key].type, data[key].category, data[key].txnCategory, data[key].merchant, data[key].details), amount: data[key].amount, total: data[key].total, status: data[key].status, txnType: data[key].txnType, bankName: data[key].bankName, accountType: data[key].accountType })
			});
			observer(transactions)
		})
	}
}