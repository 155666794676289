import { IconButton } from "@mui/material";
import { DataGrid, GridColumns, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";
import Layout from "components/Layout";
import { Colors } from "models/Colors";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import LendboxController, { Lendbox } from "services/controllers/LendboxController";

const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}

export default function LendboxView(props: { match: { params: { uid: string } } }) {
	const [lendboxList, setLendboxList] = useState<Lendbox[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const history = useHistory()
	const lendboxController = new LendboxController()
	const uid: string = props.match.params.uid


	const columns: GridColumns = [
		{ field: "id", headerName: "ID", width: 300 },
		{ field: "value", headerName: "Value", width: 200 },
	]

	const getLendbox = useRef(() => { })

	useEffect(() => {
		const fetchData = () => {
			getLendbox.current()
		}
		return fetchData()
	}, [])

	getLendbox.current = async () => {
		lendboxController.getLendbox(uid, (data) => {
			setLendboxList(data)
			if (loading)
				setLoading(false)
		})
	}
	return (
		<Layout id={2}>
			<div
				style={{
					height: "calc(100vh - 170px)",
					width: "100%"
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar,
					}}
					componentsProps={{
						toolbar: {
							goBackFunction: () => {
								history.push("/users")
							},
						}
					}}
					rows={lendboxList}
					columns={columns}
					loading={loading} />
			</div>
		</Layout>
	)
}
