import { Grid } from "@mui/material";
import Layout from "components/Layout";
import Stats from "components/stats/Stats";
import React from "react";

export default function Dashboard() {
	return (
		<Layout id={0}>
			<Grid>
				<Stats />
			</Grid>
		</Layout >
	)
}
