import { getDocs, collection } from "firebase/firestore";
import { GoalCategories } from "models/GoalCategories";
import { db } from "services/firebase";

/**
 * Interface for Goal
 */
export interface Goal {
	id: string,
	name: string,
	category: string,
	target: number,
	raised: number,
	threshold: number,
	status: string,
	type: string
}

/**
 * Types of goalStatus
 */
var goalStatus: string[] = ["Active", "Completed", "Deleted", "Paused"]

/**
 * This class is controller for interfacting withs goals from firestore
 */
export default class GoalController {

	/**
	 * Get all goals of user with uid
	 * @param uid user id
	 * @param observer Handle changes on changes
	 */
	async getGoals(uid: string, observer: (goals: Goal[]) => void) {

		const snapshot = await getDocs(collection(db, `users/${uid}/keys`));

		var goalsList: Goal[] = snapshot.docs.map((doc: any) => {
			var data = doc.data()
			data.id = doc.id
			data.category = GoalCategories[data?.category - 1]?.name ?? ""
			data.status = goalStatus[data.status]
			data.type = ((data?.type ?? " ")[0]?.toUpperCase() ?? "") + (data?.type?.substring(1) ?? "")
			return data
		})
		observer(goalsList)
	}
}