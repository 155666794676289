import { Button, Card, CssBaseline, TextField, Typography, IconButton } from "@mui/material";
import { DataGrid, GridColumns, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import Layout from "components/Layout";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import UsersController, { UserData } from "services/controllers/UsersController";
import { Colors } from "models/Colors"
import makeStyles from '@mui/styles/makeStyles';
import Center from "components/Center"
import { Cancel as CancelIcon } from "@mui/icons-material";
import firebase from "firebase/compat";

const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: Colors.background,
	},
}))

const CustomToolbar = (props: any) => {
	const { cancelFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={cancelFunction} size="large">
				<CancelIcon style={{ color: Colors.primary }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}

export default function UsersSearch() {
	const [usersList, setUsersList] = useState<UserData[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const history = useHistory()
	const classes = useStyles();
	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			const { uid, email, phone, firstName, lastName } = event.target.elements;
			try {
				const usersController = new UsersController()
				setLoading(true)
				var queries: any[] = []

				if (uid.value !== "") {
					queries.push({ 'field': firebase.firestore.FieldPath.documentId(), 'operator': '==', 'value': uid.value })
				}
				if (email.value !== "") {
					queries.push({ 'field': 'email', 'operator': '==', 'value': email.value })
				}
				if (phone.value !== "") {
					queries.push({ 'field': 'phone', 'operator': '==', 'value': phone.value })
				}
				if (firstName.value !== "") {
					queries.push({ 'field': 'firstName', 'operator': '==', 'value': firstName.value })
				}
				if (lastName.value !== "") {
					queries.push({ 'field': 'lastName', 'operator': '==', 'value': lastName.value })
				}
				usersController.getUsersByQuery((data) => {
					setUsersList(data)
					setLoading(false)
				}, queries)
			} catch (e) {
				console.log(e)
				setLoading(false)
			}
		}, []
	)
	const columns: GridColumns = [
		{ field: "id", headerName: "uid", width: 300 },
		{ field: "phone", headerName: "Phone", width: 150 },
		{ field: "email", headerName: "Email", width: 300 },
		{ field: "firstName", headerName: "First Name", width: 200 },
		{ field: "lastName", headerName: "Last Name", width: 200 },
		{ field: "lendboxBalance", headerName: "Lendbox Balance", width: 120 },
		{ field: "lendboxInvested", headerName: "Lendbox Invested", width: 120 },
		{ field: "lendboxInProgress", headerName: "Lendbox in Progress", width: 200 },
		{ field: "lendboxAvailableToWithdraw", headerName: "lendbox Available To Withdraw", width: 200 },
		{ field: "lendboxTotalInterestEarning", headerName: "Lendbox Gains", width: 200 },
		{ field: "lendboxInterestPerDay", headerName: "Lendbox Gains Per Day", width: 200 },
		{ field: "lendboxTotalWithdrawal", headerName: "Lendbox Total Withdrawal", width: 200 },
		{ field: "lendboxWithdrawalInProgress", headerName: "Lendbox Withdrawal in Progress", width: 200 },
		{ field: "mutualFundBalance", headerName: "Mutual Fund Total Balance", width: 125 },
		{ field: "invested", headerName: "Invested", width: 125 },
		{ field: "investmentValue", headerName: "Investment Value", width: 125 },
		{ field: "totalSavedSavvy", headerName: "Total Saved", width: 125 },
		{ field: "totalWithdrawnSavvy", headerName: "Total Withdrawn", width: 125 },
		{ field: "onboardingLendbox", headerName: "Lendbox Registration", width: 125 },
		{ field: "onboardingIcici", headerName: "Icici KYC", width: 125 },
		{ field: "onboardingIciciFirstTime", headerName: "First Time Transaction", width: 125 },
		{ field: "onboardingMandate", headerName: "Mandate", width: 125 },
		{ field: "mandateType", headerName: "Mandate Type", width: 125 },
		{ field: "roundup", headerName: "Round up", width: 125 },
		{ field: "recurring", headerName: "Daily Deposit", width: 125 },
		{ field: "accountPaused", headerName: "Account Paused", width: 125 },
		{ field: "smsPermission", headerName: "SMS", width: 125 },
		{ field: "os", headerName: "OS", width: 125 },
		{ field: "version", headerName: "Version", width: 125 },
		// {
		// 	field: "Goals",
		// 	headerName: "Goals",
		// 	width: 125,
		// 	renderCell: (params: any) => {
		// 		const onClick = () => {
		// 			history.push(`/users/${params.id}/goals`)
		// 		};
		// 		return (
		// 			<IconButton onClick={onClick} title={"Goals"} size="large">
		// 				<ArrowForwardIosIcon />
		// 			</IconButton>
		// 		);
		// 	}
		// },
		{
			field: "Transactions",
			headerName: "Transaction",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/transaction`)
				};
				return (
					<IconButton onClick={onClick} title={"Transaction"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{
			field: "Portfolio",
			headerName: "Portfolio",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/portfolio`)
				};
				return (
					<IconButton onClick={onClick} title={"Portfolio"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{
			field: "Lendbox",
			headerName: "Lendbox",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/lendbox`)
				};
				return (
					<IconButton onClick={onClick} title={"Transaction"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{
			field: "Mutual Fund",
			headerName: "Mutual Fund",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/mutualFund`)
				};
				return (
					<IconButton onClick={onClick} title={"Transaction"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{ field: "updatedAt", headerName: "Updated At", width: 200 },
		{ field: "createdAt", headerName: "Created At", width: 200 },
		{ field: "uuid", headerName: "UUID", width: 400 },
		{ field: "fcmToken", headerName: "FCM Token", width: 400 },
	]

	return (
		<Layout id={2}>
			<div
				style={{
					height: "calc(100vh - 170px)",
					width: "100%"
				}}>
				{usersList.length === 0 && !loading ?

					<div style={{
						backgroundColor: Colors.background,
						width: "100vw",
						height: "100vh",
						textAlign: "center",
					}}>
						<CssBaseline />
						<Center>
							<Card elevation={0} className={classes.card} style={{
								width: 400,
								padding: 20
							}} >
								<Typography variant="h5">
									Users Search
								</Typography>
								<form onSubmit={handleSubmit}>
									<div style={{
										padding: 10
									}} />
									<TextField id="uid" type="uid" label="UID" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="email" type="email" label="Email" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="phone" type="tel" label="Phone" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="firstName" type="text" label="First Name" fullWidth />
									<div style={{
										padding: 10
									}} />
									<TextField id="lastName" type="text" label="Last Name" fullWidth />
									<div style={{
										padding: 10
									}} />
									<Button disabled={loading} type="submit" fullWidth={true}>Submit</Button >
								</form>
							</Card>
						</Center>
					</div>
					:
					<DataGrid
						components={{
							Toolbar: CustomToolbar,
						}}
						componentsProps={{
							toolbar: {
								cancelFunction: () => {
									setUsersList([])
								},
							}
						}}
						rows={usersList}
						columns={columns}
						loading={loading} />
				}
			</div>
		</Layout>
	)
}