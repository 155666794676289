import { IconButton } from "@mui/material";
import { DataGrid, GridColumns, GridToolbar } from "@mui/x-data-grid";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import Layout from "components/Layout";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import UsersController, { UserData } from "services/controllers/UsersController";

export default function Users() {
	const [usersList, setUsersList] = useState<UserData[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const usersController = new UsersController()
	const history = useHistory()

	useEffect(() => {
		const fetchData = () => {
			getUsers.current()
		}
		return fetchData()
	}, [])

	const columns: GridColumns = [
		{ field: "id", headerName: "uid", width: 300 },
		{ field: "phone", headerName: "Phone", width: 150 },
		{ field: "email", headerName: "Email", width: 300 },
		{ field: "firstName", headerName: "First Name", width: 200 },
		{ field: "lastName", headerName: "Last Name", width: 200 },
		{ field: "lendboxBalance", headerName: "Lendbox Balance", width: 120 },
		{ field: "lendboxAvailableToWithdraw", headerName: "lendbox Available To Withdraw", width: 200 },
		{ field: "lendboxAmountWithdrawn", headerName: "Lendbox Amount Withdrawn", width: 200 },
		{ field: "lendboxInProgress", headerName: "Lendbox in Progress", width: 200 },
		{ field: "lendboxTotalSaved", headerName: "Lendbox Total Saved", width: 200 },
		{ field: "lendboxWithdrawalInProgress", headerName: "Lendbox Withdrawal in Progress", width: 200 },
		{ field: "mutualFundBalance", headerName: "Mutual Fund Total Balance", width: 125 },
		{ field: "invested", headerName: "Invested", width: 125 },
		{ field: "investmentValue", headerName: "Investment Value", width: 125 },
		{ field: "totalSavedSavvy", headerName: "Total Saved", width: 125 },
		{ field: "totalWithdrawnSavvy", headerName: "Total Withdrawn", width: 125 },
		{ field: "onboardingLendbox", headerName: "Lendbox Registration", width: 125 },
		{ field: "onboardingIcici", headerName: "Icici KYC", width: 125 },
		{ field: "onboardingIciciFirstTime", headerName: "First Time Transaction", width: 125 },
		{ field: "onboardingMandate", headerName: "Mandate", width: 125 },
		{ field: "mandateType", headerName: "Mandate Type", width: 125 },
		{ field: "roundup", headerName: "Round up", width: 125 },
		{ field: "recurring", headerName: "Daily Deposit", width: 125 },
		{ field: "accountPaused", headerName: "Account Paused", width: 125 },
		{ field: "totalRewardEarned", headerName: "Total Reward Earned", width: 125 },
		{ field: "smsPermission", headerName: "SMS", width: 125 },
		{ field: "os", headerName: "OS", width: 125 },
		{ field: "version", headerName: "Version", width: 125 },
		{
			field: "Goals",
			headerName: "Goals",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/goals`)
				};
				return (
					<IconButton onClick={onClick} title={"Goals"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{
			field: "Transactions",
			headerName: "Transaction",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/transaction`)
				};
				return (
					<IconButton onClick={onClick} title={"Transaction"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{
			field: "Portfolio",
			headerName: "Portfolio",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/portfolio`)
				};
				return (
					<IconButton onClick={onClick} title={"Portfolio"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{
			field: "Mutual Fund",
			headerName: "Mutual Fund",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${params.id}/mutualFund`)
				};
				return (
					<IconButton onClick={onClick} title={"Transaction"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
		{ field: "uuid", headerName: "UUID", width: 400 },
		{ field: "updatedAt", headerName: "Updated At", width: 200 },
		{ field: "createdAt", headerName: "Created At", width: 200 },
		{ field: "fcmToken", headerName: "FCM Token", width: 400 },
	]

	const getUsers = useRef(() => { })

	getUsers.current = async () => {
		usersController.getUsers((data) => {
			setUsersList(data)
			if (loading)
				setLoading(false)
		})
	}

	return (
		<Layout id={2}>
			<div
				style={{
					height: "calc(100vh - 170px)",
					width: "100%"
				}}>
				<DataGrid
					components={{
						Toolbar: GridToolbar
					}}
					rows={usersList}
					columns={columns}
					loading={loading} />
			</div>
		</Layout>
	)
}
