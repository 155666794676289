import { AppBar, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Dashboard as DashboardIcon, DeveloperBoard as DeveloperBoardIcon, Menu as MenuIcon, PersonSearch as PersonSearchIcon } from "@mui/icons-material";
import logo from "assets/logo.png";
import clsx from "clsx";
import { Colors } from "models/Colors";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "services/contexts/AuthContext";
import { useLayout } from "services/contexts/LayoutContext";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	paper: {
		backgroundColor: Colors.background
	},
	title: {
		flexGrow: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: theme.palette.primary.main
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		backgroundColor: Colors.background
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: Colors.background
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: 75,
		backgroundColor: Colors.background
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 0),
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},

	toolbarIcon: {
		padding: theme.spacing(0, 2.5, 0, 0.0)
	},

	listPadding: {
		padding: theme.spacing(1, 0, 1, 2.9)
	}
}));

/**
 * Layout of the app
 * @param children children components
 * @param id id for current color of list item on drawer
 * @returns Layout consisting of AppBar, Drawer and the children param
 */
export default function Layout(props: { children: any, id: number }) {
	const [error, setError] = useState("")
	const { currentUser, logout } = useAuth()
	const { drawerOpen, handleDrawer } = useLayout()
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const history = useHistory()
	const classes = useStyles();

	async function handleLogout() {
		setError("")
		try {
			await logout()
			history.push("/login")
		} catch {
			setError("Failed to log out")
			alert(error)
		}
	}

	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const color = [(props.id === 0) ? Colors.primary : "#fff", (props.id === 1) ? Colors.primary : "#fff", (props.id === 2) ? Colors.primary : "#fff", (props.id === 3) ? Colors.primary : "#fff"]

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				style={{ background: Colors.background, boxShadow: "none" }}
				position="fixed"
				className={clsx(classes.appBar)}>
				<Toolbar>
					<div className={classes.toolbarIcon}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawer}
							edge="start"
							size="large">
							<MenuIcon />
						</IconButton>
					</div>
					<Typography variant="h6" className={classes.title}>Deciml Admin</Typography>
					<IconButton
						className={classes.menuButton}
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
						size="large">
						<img src={logo} alt="Logo" style={{
							height: 50,
							paddingLeft: 5
						}} />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={open}
						onClose={handleClose}>
						<MenuItem >{currentUser.email}</MenuItem>
						<MenuItem onClick={handleLogout}>Log Out</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: drawerOpen,
					[classes.drawerClose]: !drawerOpen,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: drawerOpen,
						[classes.drawerClose]: !drawerOpen,
					}),
				}}>
				<div className={classes.toolbar} />
				<Divider />
				<List >
					<ListItem button
						onClick={() => { history.push("/") }}
						className={classes.listPadding}>
						<ListItemIcon><DashboardIcon
							style={{ color: color[0] }}
						/></ListItemIcon>
						<ListItemText primary={"Dashboard"}
							style={{ color: color[0] }}
						/>
					</ListItem>
					<ListItem button
						onClick={() => { history.push("/developer") }}
						className={classes.listPadding}>
						<ListItemIcon><DeveloperBoardIcon
							style={{ color: color[1] }}
						/></ListItemIcon>
						<ListItemText primary={"Developers"}
							style={{ color: color[1] }}
						/>
					</ListItem>
					<ListItem button
						onClick={() => { history.push("/users") }}
						className={classes.listPadding}>
						<ListItemIcon>
							<PersonSearchIcon
								style={{ color: color[2] }}
							/></ListItemIcon>
						<ListItemText primary={"Users"}
							style={{ color: color[2] }}
						/>
					</ListItem>
					{/* <ListItem button
						onClick={() => { history.push("/usersOld") }}
						className={classes.listPadding}>
						<ListItemIcon>
							<PeopleIcon
								style={{ color: color[3] }}
							/></ListItemIcon>
						<ListItemText primary={"Users Old"}
							style={{ color: color[3] }}
						/>
					</ListItem> */}
				</List>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
		</div>
	);
}
