import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "services/firebase";
import getMonth from "services/util/getMonth";
import monthConvert from "services/util/monthConvert";

export interface PortfolioTransactionMonth {
	id: string,
	monthCode: string,
	month: string,
}
export interface PortfolioTransaction {
	id: string,
	amount: number,
	status: string,
	type: string,
	transactionId: string,
	ip: string | undefined,
}
export default class PortfolioController {
	getPortfolioMonths(uid: string, observer: (transactionMonths: PortfolioTransactionMonth[]) => void) {
		onSnapshot(collection(db, `users/${uid}/portfolio/lendbox/transactions`), (snapshot: any) => {
			var transactionMonths: PortfolioTransactionMonth[] = snapshot.docs.map((doc: any) => {
				var data = doc.data()
				data.id = doc.id
				data.monthCode = monthConvert(doc.id)
				data.month = getMonth(data.monthCode)
				return data
			})
			transactionMonths.sort((a: PortfolioTransactionMonth, b: PortfolioTransactionMonth) => {
				if (a.monthCode > b.monthCode) return -1
				if (a.monthCode < b.monthCode) return 1
				return 0
			})
			observer(transactionMonths)
		})
	}
	getPortfolio(uid: string, month: string, observer: (transactions: PortfolioTransaction[]) => void) {
		onSnapshot(doc(db, `users/${uid}/portfolio/lendbox/transactions/${month}`), (snapshot: any) => {
			var data: any = snapshot.data()
			var transactions: PortfolioTransaction[] = []
			Object.keys(data).sort().forEach((key: string) => {
				transactions.push({ id: key, type: data[key].type, amount: data[key].amount, status: data[key].status, transactionId: data[key].transactionId, ip: data[key].ip })
			});
			observer(transactions)
		})
	}
}