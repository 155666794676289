import { createTheme, Theme } from "@mui/material/styles";
import { Colors } from "models/Colors";
import "@mui/styles"
declare module '@mui/styles' {
	interface DefaultTheme extends Theme { }
}


const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: '#3A8FF6',
			dark: '#3A8FF6',
		},
		background: {
			default: Colors.background,
		},
	}
})
export default theme;