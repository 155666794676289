import React, { useContext, useState } from "react"

const LayoutContext = React.createContext<any>(null)

export function useLayout() {
	return useContext(LayoutContext)
}

export function LayoutProvider(props: { children: any }) {

	const [drawerOpen, setDrawerOpen] = useState(false)

	function handleDrawer() {
		setDrawerOpen(!drawerOpen)
	}

	const value = {
		drawerOpen, handleDrawer
	}

	return (
		<LayoutContext.Provider value={value}>
			{props.children}
		</LayoutContext.Provider>
	)
}