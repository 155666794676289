import { getDoc, doc } from "firebase/firestore";
import { db } from "services/firebase";

/**
 * Interface for MutualFund
 */
export interface MutualFund {
	id: string,
	value: any,
}

/**
 * This class is controller for interfacting withs mutualFunds from firestore
 */
export default class MutualFundController {

	/**
	 * Get all mutualFunds of user with uid
	 * @param uid user id
	 * @param observer Handle changes on changes
	 */
	async getMutualFund(uid: string, observer: (mutualFund: MutualFund[]) => void) {

		/**
		 * Reference for mutualFund document
		 */
		const snapshot = await getDoc(doc(db, `users/${uid}/data/mutualFund`));

		var data: any = snapshot.data()
		var mutualFund: MutualFund[] = []
		Object.keys(data).sort().forEach((key: string) => {
			mutualFund.push({ id: key, value: data[key] })
		});
		observer(mutualFund)
	}
}