import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Icon, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Refresh as RefreshIcon, BarChart as BarChartIcon, ShowChart as ShowChartIcon } from "@mui/icons-material";
import InvestmentCard from "components/stats/InvestmentCard";
import { UserDailyGraph } from "components/stats/UserDailyGraph";
import { Colors } from "models/Colors";
import React, { useEffect, useRef, useState } from "react";
import StatsController from "services/controllers/StatsController";
import monthConvert from "services/util/monthConvert";
import getMonth from "services/util/getMonth";
import { UserStatGraph } from "./UserStatGraph";
import currencyFormat from "services/util/currencyFormat";
import StatsAreaGraph from "./mini-components/StatsAreaGraph";
import StatsBarGraph from "./mini-components/StatsBarGraph";

var statsController = new StatsController();

const useStyles = makeStyles((_) => {
	return {
		card: {
			backgroundColor: Colors.secondaryBackground,
			height: 400,
			background: Colors.secondaryBackground

		},
		mainCard: {
			backgroundColor: Colors.secondaryBackground,
			height: 500
		},
		cardContent: {
			alignContent: "center",
			textAlign: "center"
		},
		gridList: {
			height: 360,
		},
		total: {
			color: Colors.green
		},
		kyc: {
			color: Colors.orange
		},
		goals: {
			color: Colors.purple
		},
		investments: {
			color: Colors.primary
		},
		active: {
			color: Colors.lightBlue
		}
	}
}
);

export default function Stats() {

	const [usersStats, setUsersStats] = useState<any>({})
	const [usersLendboxGraphData, setUsersLendboxGraphData] = useState<any[]>([])
	const [usersTotalGraphData, setUsersTotalGraphData] = useState<any[]>([])
	const classes = useStyles();
	const userTotalGraphRef = useRef<any>();
	const userLendboxGraphRef = useRef<any>();
	const userTotalMonthlyGraphRef = useRef<any>();
	const userLendboxMonthlyGraphRef = useRef<any>();
	const [investmentStatsData, setInvestmentStatsData] = useState<any>({})
	const [investmentGraphData, setInvestmentGraphData] = useState<any>([])
	const [userMonthlyData, setUserMonthlyData] = useState<any>([])
	const [usersPausedGraphData, setUsersPausedGraphData] = useState<any[]>([])
	const [usersOnboardedGraphData, setUsersOnboardedGraphData] = useState<any[]>([])
	const [usersMandateExecutionsGraphData, setUsersMandateExecutionsGraphData] = useState<any[]>([])
	const [usersMandateExecutionsAmountGraphData, setUsersMandateExecutionsAmountGraphData] = useState<any[]>([])
	const [usersActiveGraphData, setUsersActiveGraphData] = useState<any[]>([])
	const [usersYesbankMandateGraphData, setUsersYesbankMandateGraphData] = useState<any[]>([])
	const [usersRoundUpGraphData, setUsersRoundUpGraphData] = useState<any[]>([])
	const [usersDailyDepositGraphData, setUsersDailyDepositGraphData] = useState<any[]>([])
	const [usersContactGraphData, setUsersContactGraphData] = useState<any[]>([])

	useEffect(() => {
		const fetchData = () => {
			getUserStatsData.current()
			getInvestmentStatsData.current()
			getUserTotalGraphData.current()
			getInvestmentGraphData.current()
			getUserLendboxGraphData.current()
			getUserPausedGraphData.current()
			getUserOnboardedGraphData.current()
			getUserMandateExecutionsGraphData.current()
			getUserMandateExecutionsAmountGraphData.current()
			getUserActiveGraphData.current()
			getUserYesbankMandateGraphData.current()
			getUserRoundupGraphData.current()
			getUserDailyDepositGraphData.current()
			getUserContactGraphData.current()
		}
		return fetchData()
	}, [])

	const getData = async () => {
		await getUserTotalGraphData.current()
		await getUserActiveGraphData.current()
		await getUserOnboardedGraphData.current()
		await getUserLendboxGraphData.current()
		await getInvestmentGraphData.current()
		await getUserPausedGraphData.current()
		await getUserMandateExecutionsGraphData.current()
		await getUserMandateExecutionsAmountGraphData.current()
		await getUserYesbankMandateGraphData.current()
		await getUserRoundupGraphData.current()
		await getUserDailyDepositGraphData.current()
		await getUserContactGraphData.current()
	}

	const getUserStatsData = useRef(() => { })

	getUserStatsData.current = () => {
		statsController.getUserStatsData(
			(data: any) => {
				setUsersStats(data)
				var userMonthlyDataList: any[] = []
				for (var i in data.data) {
					var d: any = {}
					d.monthCode = monthConvert(i)
					d.month = getMonth(d.monthCode)
					d.showDate = d.month
					d.date = d.month
					d.total = data?.data[i]?.total ?? 0
					d.kyc = data?.data[i]?.kyc ?? 0
					d.active = data?.data[i]?.active ?? 0
					d.lendbox = data?.data[i]?.lendbox ?? 0
					userMonthlyDataList.push(d)
				}
				userMonthlyDataList.sort((a: any, b: any) => {
					if (a.monthCode > b.monthCode) return 1
					if (a.monthCode < b.monthCode) return -1
					return 0
				})
				var totalTotal = 0
				var kycTotal = 0
				var lendboxTotal = 0
				for (var x = 0; x < userMonthlyDataList.length; x++) {
					userMonthlyDataList[x].totalString = userMonthlyDataList[x].total
					userMonthlyDataList[x].kycString = userMonthlyDataList[x].kyc
					userMonthlyDataList[x].lendboxString = userMonthlyDataList[x].lendbox
					userMonthlyDataList[x].activeString = userMonthlyDataList[x].active
					userMonthlyDataList[x].totalValue = userMonthlyDataList[x].total
					userMonthlyDataList[x].kycValue = userMonthlyDataList[x].kyc
					userMonthlyDataList[x].lendboxValue = userMonthlyDataList[x].lendbox
					userMonthlyDataList[x].activeValue = userMonthlyDataList[x].active
					totalTotal += userMonthlyDataList[x].total
					kycTotal += userMonthlyDataList[x].kyc
					lendboxTotal += userMonthlyDataList[x].lendbox
					userMonthlyDataList[x].total = totalTotal
					userMonthlyDataList[x].kyc = kycTotal
					userMonthlyDataList[x].lendbox = lendboxTotal
				}
				setUserMonthlyData(userMonthlyDataList)
			}
		)
	}
	const getInvestmentStatsData = useRef(() => { })

	getInvestmentStatsData.current = () => {
		statsController.getInvestmentStatsData((data: any) => {
			setInvestmentStatsData(data)
		})
	}

	const getInvestmentGraphData = useRef(() => { })

	getInvestmentGraphData.current = async () => {
		var totalShownDays = 30
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var graphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getInvestmentGraph(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						graphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							balance: ((data["" + (i)]?.lendbox?.balance) ?? 0),
							invested: ((data["" + (i)]?.lendbox?.invested) ?? 0),
							inProgress: ((data["" + (i)]?.lendbox?.inProgress) ?? 0),
							roundup: ((data["" + (i)]?.lendbox?.roundup) ?? 0),
							roundupSpends: ((data["" + (i)]?.lendbox?.roundupSpends) ?? 0),
							dailyDeposit: ((data["" + (i)]?.lendbox?.dailyDeposit) ?? 0),
							dailyDepositDays: ((data["" + (i)]?.lendbox?.dailyDepositDays) ?? 0),
							directInvested: ((data["" + (i)]?.lendbox?.directInvested) ?? 0),
							totalInterestEarning: ((data["" + (i)]?.lendbox?.totalInterestEarning) ?? 0),
							totalSaved: ((data["" + (i)]?.lendbox?.totalSaved) ?? 0),
							totalWithdrawal: ((data["" + (i)]?.lendbox?.totalWithdrawal) ?? 0),
							withdrawalInProgress: ((data["" + (i)]?.lendbox?.withdrawalInProgress) ?? 0),
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getInvestmentGraph(month, year).then(data => {
			for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
				graphData.push({
					date: i,
					showDate: i + "/" + month + "/" + year,
					balance: ((data["" + (i)]?.lendbox?.balance) ?? 0),
					invested: ((data["" + (i)]?.lendbox?.invested) ?? 0),
					inProgress: ((data["" + (i)]?.lendbox?.inProgress) ?? 0),
					roundup: ((data["" + (i)]?.lendbox?.roundup) ?? 0),
					roundupSpends: ((data["" + (i)]?.lendbox?.roundupSpends) ?? 0),
					dailyDeposit: ((data["" + (i)]?.lendbox?.dailyDeposit) ?? 0),
					dailyDepositDays: ((data["" + (i)]?.lendbox?.dailyDepositDays) ?? 0),
					directInvested: ((data["" + (i)]?.lendbox?.directInvested) ?? 0),
					totalInterestEarning: ((data["" + (i)]?.lendbox?.totalInterestEarning) ?? 0),
					totalSaved: ((data["" + (i)]?.lendbox?.totalSaved) ?? 0),
					totalWithdrawal: ((data["" + (i)]?.lendbox?.totalWithdrawal) ?? 0),
					withdrawalInProgress: ((data["" + (i)]?.lendbox?.withdrawalInProgress) ?? 0),
				})
			}
		});
		for (var i = 0; i < graphData.length; i++) {
			graphData[i].withdrawalRate = ((graphData[i].totalWithdrawal + graphData[i].withdrawalInProgress) / (graphData[i].balance + graphData[i].totalWithdrawal))
			graphData[i].roundupAverage = (graphData[i].roundup ?? 0) / (graphData[i].roundupSpends ?? 1)
			graphData[i].dailyDepositAverage = (graphData[i].dailyDeposit ?? 0) / (graphData[i].dailyDepositDays ?? 1)
			graphData[i].balanceString = currencyFormat(graphData[i].balance)
			graphData[i].investedString = currencyFormat(graphData[i].invested)
			graphData[i].inProgressString = currencyFormat(graphData[i].inProgress)
			graphData[i].mutualFundBalanceString = currencyFormat(graphData[i].mutualFundBalance)
			graphData[i].totalSavedString = currencyFormat(graphData[i].totalSaved)
			graphData[i].totalWithdrawalString = currencyFormat(graphData[i].totalWithdrawal)
			graphData[i].directInvestedString = currencyFormat(graphData[i].directInvested)
			graphData[i].dailyDepositString = currencyFormat(graphData[i].dailyDeposit)
			graphData[i].dailyDepositDaysString = graphData[i].dailyDepositDays
			graphData[i].roundupAverageString = currencyFormat(graphData[i].roundupAverage)
			graphData[i].roundupString = currencyFormat(graphData[i].roundup)
			graphData[i].roundupSpendsString = graphData[i].roundupSpends
			graphData[i].dailyDepositAverageString = currencyFormat(graphData[i].dailyDepositAverage)
			graphData[i].withdrawalRateString = (graphData[i].withdrawalRate * 100).toFixed(2) + '%'
			if (i !== 0) {
				if (graphData[i].balance > graphData[i - 1].balance) {
					graphData[i].balanceString += ' (+' + (((graphData[i].balance - graphData[i - 1].balance) / graphData[i - 1].balance) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].balance - graphData[i - 1].balance)) + ')'
				} else if (graphData[i].balance < graphData[i - 1].balance) {
					graphData[i].balanceString += ' (-' + (((graphData[i - 1].balance - graphData[i].balance) / graphData[i].balance) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].balance - graphData[i].balance)) + ')'
				} else {
					graphData[i].balanceString += ' (0%)'
				}
				if (graphData[i].invested > graphData[i - 1].invested) {
					graphData[i].investedString += ' (+' + (((graphData[i].invested - graphData[i - 1].invested) / graphData[i - 1].invested) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].invested - graphData[i - 1].invested)) + ')'
				} else if (graphData[i].invested < graphData[i - 1].invested) {
					graphData[i].investedString += ' (-' + (((graphData[i - 1].invested - graphData[i].invested) / graphData[i].invested) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].invested - graphData[i].invested)) + ')'
				} else {
					graphData[i].investedString += ' (0%)'
				}
				if (graphData[i].inProgress > graphData[i - 1].inProgress) {
					graphData[i].inProgressString += ' (+' + (((graphData[i].inProgress - graphData[i - 1].inProgress) / graphData[i - 1].inProgress) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].inProgress - graphData[i - 1].inProgress)) + ')'
				} else if (graphData[i].inProgress < graphData[i - 1].inProgress) {
					graphData[i].inProgressString += ' (-' + (((graphData[i - 1].inProgress - graphData[i].inProgress) / graphData[i].inProgress) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].inProgress - graphData[i].inProgress)) + ')'
				} else {
					graphData[i].inProgressString += ' (0%)'
				}
				if (graphData[i].totalSaved > graphData[i - 1].totalSaved) {
					graphData[i].totalSavedString += ' (+' + (((graphData[i].totalSaved - graphData[i - 1].totalSaved) / graphData[i - 1].totalSaved) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].totalSaved - graphData[i - 1].totalSaved)) + ')'
				} else if (graphData[i].totalSaved < graphData[i - 1].totalSaved) {
					graphData[i].totalSavedString += ' (-' + (((graphData[i - 1].totalSaved - graphData[i].totalSaved) / graphData[i].totalSaved) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].totalSaved - graphData[i].totalSaved)) + ')'
				} else {
					graphData[i].totalSavedString += ' (0%)'
				}
				if (graphData[i].totalWithdrawal > graphData[i - 1].totalWithdrawal) {
					graphData[i].totalWithdrawalString += ' (+' + (((graphData[i].totalWithdrawal - graphData[i - 1].totalWithdrawal) / graphData[i - 1].totalWithdrawal) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].totalWithdrawal - graphData[i - 1].totalWithdrawal)) + ')'
				} else if (graphData[i].totalWithdrawal < graphData[i - 1].totalWithdrawal) {
					graphData[i].totalWithdrawalString += ' (-' + (((graphData[i - 1].totalWithdrawal - graphData[i].totalWithdrawal) / graphData[i].totalWithdrawal) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].totalWithdrawal - graphData[i].totalWithdrawal)) + ')'
				} else {
					graphData[i].totalWithdrawalString += ' (0%)'
				}
				if (graphData[i].withdrawalRate > graphData[i - 1].withdrawalRate) {
					graphData[i].withdrawalRateString += ' (+' + ((graphData[i].withdrawalRate - graphData[i - 1].withdrawalRate) * 100).toFixed(2) + '%)'
				} else if (graphData[i].withdrawalRate < graphData[i - 1].withdrawalRate) {
					graphData[i].withdrawalRateString += ' (-' + ((graphData[i - 1].withdrawalRate - graphData[i].withdrawalRate) * 100).toFixed(2) + '%)'
				} else {
					graphData[i].withdrawalRateString += ' (0%)'
				}
				if (graphData[i].roundupSpends > graphData[i - 1].roundupSpends) {
					graphData[i].roundupSpendsString += ' (+' + (((graphData[i].roundupSpends - graphData[i - 1].roundupSpends) / graphData[i - 1].roundupSpends) * 100).toFixed(2) + '%) (' + (graphData[i].roundupSpends - graphData[i - 1].roundupSpends) + ')'
				} else if (graphData[i].roundupSpends < graphData[i - 1].roundupSpends) {
					graphData[i].roundupSpendsString += ' (-' + (((graphData[i - 1].roundupSpends - graphData[i].roundupSpends) / graphData[i].roundupSpends) * 100).toFixed(2) + '%) (' + (graphData[i - 1].roundupSpends - graphData[i].roundupSpends) + ')'
				} else {
					graphData[i].roundupSpendsString += ' (0%)'
				}
				if (graphData[i].dailyDepositDays > graphData[i - 1].dailyDepositDays) {
					graphData[i].dailyDepositDaysString += ' (+' + (((graphData[i].dailyDepositDays - graphData[i - 1].dailyDepositDays) / graphData[i - 1].dailyDepositDays) * 100).toFixed(2) + '%) (' + (graphData[i].dailyDepositDays - graphData[i - 1].dailyDepositDays) + ')'
				} else if (graphData[i].dailyDepositDays < graphData[i - 1].dailyDepositDays) {
					graphData[i].dailyDepositDaysString += ' (-' + (((graphData[i - 1].dailyDepositDays - graphData[i].dailyDepositDays) / graphData[i].dailyDepositDays) * 100).toFixed(2) + '%) (' + (graphData[i - 1].dailyDepositDays - graphData[i].dailyDepositDays) + ')'
				} else {
					graphData[i].dailyDepositDaysString += ' (0%)'
				}
				if (graphData[i].directInvested > graphData[i - 1].directInvested) {
					graphData[i].directInvestedString += ' (+' + (((graphData[i].directInvested - graphData[i - 1].directInvested) / graphData[i - 1].directInvested) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].directInvested - graphData[i - 1].directInvested)) + ')'
				} else if (graphData[i].directInvested < graphData[i - 1].directInvested) {
					graphData[i].directInvestedString += ' (-' + (((graphData[i - 1].directInvested - graphData[i].directInvested) / graphData[i].directInvested) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].directInvested - graphData[i].directInvested)) + ')'
				} else {
					graphData[i].directInvestedString += ' (0%)'
				}
				if (graphData[i].dailyDeposit > graphData[i - 1].dailyDeposit) {
					graphData[i].dailyDepositString += ' (+' + (((graphData[i].dailyDeposit - graphData[i - 1].dailyDeposit) / graphData[i - 1].dailyDeposit) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].dailyDeposit - graphData[i - 1].dailyDeposit)) + ')'
				} else if (graphData[i].dailyDeposit < graphData[i - 1].dailyDeposit) {
					graphData[i].dailyDepositString += ' (-' + (((graphData[i - 1].dailyDeposit - graphData[i].dailyDeposit) / graphData[i].dailyDeposit) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].dailyDeposit - graphData[i].dailyDeposit)) + ')'
				} else {
					graphData[i].dailyDepositString += ' (0%)'
				}
				if (graphData[i].roundup > graphData[i - 1].roundup) {
					graphData[i].roundupString += ' (+' + (((graphData[i].roundup - graphData[i - 1].roundup) / graphData[i - 1].roundup) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].roundup - graphData[i - 1].roundup)) + ')'
				} else if (graphData[i].roundup < graphData[i - 1].roundup) {
					graphData[i].roundupString += ' (-' + (((graphData[i - 1].roundup - graphData[i].roundup) / graphData[i].roundup) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].roundup - graphData[i].roundup)) + ')'
				} else {
					graphData[i].roundupString += ' (0%)'
				}
				if (graphData[i].roundupAverage > graphData[i - 1].roundupAverage) {
					graphData[i].roundupAverageString += ' (+' + (((graphData[i].roundupAverage - graphData[i - 1].roundupAverage) / graphData[i - 1].roundupAverage) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].roundupAverage - graphData[i - 1].roundupAverage)) + ')'
				} else if (graphData[i].roundupAverage < graphData[i - 1].roundupAverage) {
					graphData[i].roundupAverageString += ' (-' + (((graphData[i - 1].roundupAverage - graphData[i].roundupAverage) / graphData[i].roundupAverage) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].roundupAverage - graphData[i].roundupAverage)) + ')'
				} else {
					graphData[i].roundupAverageString += ' (0%)'
				}
				if (graphData[i].dailyDepositAverage > graphData[i - 1].dailyDepositAverage) {
					graphData[i].dailyDepositAverageString += ' (+' + (((graphData[i].dailyDepositAverage - graphData[i - 1].dailyDepositAverage) / graphData[i - 1].dailyDepositAverage) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i].dailyDepositAverage - graphData[i - 1].dailyDepositAverage)) + ')'
				} else if (graphData[i].dailyDepositAverage < graphData[i - 1].dailyDepositAverage) {
					graphData[i].dailyDepositAverageString += ' (-' + (((graphData[i - 1].dailyDepositAverage - graphData[i].dailyDepositAverage) / graphData[i].dailyDepositAverage) * 100).toFixed(2) + '%) (' + currencyFormat((graphData[i - 1].dailyDepositAverage - graphData[i].dailyDepositAverage)) + ')'
				} else {
					graphData[i].dailyDepositAverageString += ' (0%)'
				}
			}
		}
		setInvestmentGraphData(graphData)
	}

	const getUserLendboxGraphData = useRef(() => { })

	getUserLendboxGraphData.current = async () => {
		var totalShownDays = 30
		var usersLendboxData: any = {}
		var now = Date.now()
		var date = new Date(now)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersLendboxGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserLendboxGraphData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersLendboxData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersLendboxGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserLendboxGraphData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersLendboxData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersLendboxGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0
					})
				}
			});
		var total = 0
		for (var i = 0; i < usersLendboxGraphData.length; i++) {
			if (usersLendboxGraphData[i].total === 0) {
				usersLendboxGraphData[i].totalString = 'No new KYC Verifications complete'
			}
			else {
				usersLendboxGraphData[i].totalString = `${usersLendboxGraphData[i].total} new KYC verification${usersLendboxGraphData[i].total > 1 ? 's' : ''} complete`
			}
			usersLendboxGraphData[i].totalValue = usersLendboxGraphData[i].total
			total += usersLendboxGraphData[i]?.total ?? 0
			usersLendboxGraphData[i].total = total
		}
		setUsersLendboxGraphData(usersLendboxGraphData)
	}

	const getUserTotalGraphData = useRef(() => { })

	getUserTotalGraphData.current = async () => {
		var totalShownDays = 30
		var usersTotalData: any = {}
		var now = Date.now()
		var date = new Date(now)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersTotalGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserTotalStatsData(nMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersTotalData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersTotalGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserTotalStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersTotalData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersTotalGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0
					})
				}
			});
		var total = 0
		for (var i = 0; i < usersTotalGraphData.length; i++) {
			if (usersTotalGraphData[i].total === 0) {
				usersTotalGraphData[i].totalString = 'No new users'
			}
			else {
				usersTotalGraphData[i].totalString = `${usersTotalGraphData[i].total} new user${usersTotalGraphData[i].total > 1 ? 's' : ''}`
			}
			usersTotalGraphData[i].totalValue = usersTotalGraphData[i].total
			total += usersTotalGraphData[i]?.total ?? 0
			usersTotalGraphData[i].total = total
		}
		setUsersTotalGraphData(usersTotalGraphData)
	}

	const getUserPausedGraphData = useRef(() => { })

	getUserPausedGraphData.current = async () => {
		var totalShownDays = 30
		var usersPausedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersPausedGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserPausedStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersPausedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersPausedGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
							lendbox: (data["" + (i)]?.lendbox) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserPausedStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersPausedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersPausedGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0,
						lendbox: (data["" + (i)]?.lendbox) ?? 0
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersPausedGraphData.length; i++) {
			if (i === 0) {
				minValue = usersPausedGraphData[i].total
			} else {
				if (usersPausedGraphData[i].total < minValue) {
					minValue = usersPausedGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersPausedGraphData.length; i++) {
			if (usersPausedGraphData[i].total === 0) {
				usersPausedGraphData[i].totalString = 'No Paused Users'
			}
			else {
				usersPausedGraphData[i].totalString = `${usersPausedGraphData[i].total} Paused User${usersPausedGraphData[i].total > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].totalValue = usersPausedGraphData[i].total
			if (usersPausedGraphData[i].lendbox === 0) {
				usersPausedGraphData[i].lendboxString = 'No Paused Users'
			}
			else {
				usersPausedGraphData[i].lendboxString = `${usersPausedGraphData[i].lendbox} Paused User${usersPausedGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersPausedGraphData[i].lendboxValue = usersPausedGraphData[i].lendbox
		}
		setUsersPausedGraphData(usersPausedGraphData)
	}

	const getUserOnboardedGraphData = useRef(() => { })

	getUserOnboardedGraphData.current = async () => {
		var totalShownDays = Math.min(Math.ceil((Date.now() - new Date(2022, 5, 2).valueOf()) / (1000 * 60 * 60 * 24)), 30)
		var usersOnboardedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersOnboardedGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserOnboardedStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersOnboardedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersOnboardedGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
							lendbox: (data["" + (i)]?.lendbox) ?? 0,
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserOnboardedStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersOnboardedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersOnboardedGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: (data["" + (i)]?.total) ?? 0,
						lendbox: data["" + (i)]?.lendbox ?? 0,
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersOnboardedGraphData.length; i++) {
			if (i === 0) {
				minValue = usersOnboardedGraphData[i].total
			} else {
				if (usersOnboardedGraphData[i].total < minValue) {
					minValue = usersOnboardedGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersOnboardedGraphData.length; i++) {
			if (usersOnboardedGraphData[i].total === 0) {
				usersOnboardedGraphData[i].totalString = 'No Onboarded Users'
			}
			else {
				usersOnboardedGraphData[i].totalString = `${usersOnboardedGraphData[i].total} Onboarded User${usersOnboardedGraphData[i].total > 1 ? 's' : ''}`
			}
			if (usersOnboardedGraphData[i].lendbox === 0) {
				usersOnboardedGraphData[i].lendboxString = 'No Onboarded Users'
			}
			else {
				usersOnboardedGraphData[i].lendboxString = `${usersOnboardedGraphData[i].lendbox} Onboarded User${usersOnboardedGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersOnboardedGraphData[i].totalValue = usersOnboardedGraphData[i].total
		}
		setUsersOnboardedGraphData(usersOnboardedGraphData)
	}

	const getUserMandateExecutionsGraphData = useRef(() => { })

	getUserMandateExecutionsGraphData.current = async () => {
		var totalShownDays = 30
		var usersOnboardedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		// date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersMandateExecutionsGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserMandateExecutionsStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersOnboardedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersMandateExecutionsGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserMandateExecutionsStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersOnboardedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersMandateExecutionsGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: (data["" + (i)]?.total) ?? 0,
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersMandateExecutionsGraphData.length; i++) {
			if (i === 0) {
				minValue = usersMandateExecutionsGraphData[i].total
			} else {
				if (usersMandateExecutionsGraphData[i].total < minValue) {
					minValue = usersMandateExecutionsGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersMandateExecutionsGraphData.length; i++) {
			if (usersMandateExecutionsGraphData[i].total === 0) {
				usersMandateExecutionsGraphData[i].totalString = 'No Mandate Executions'
			}
			else {
				usersMandateExecutionsGraphData[i].totalString = `${usersMandateExecutionsGraphData[i].total} Mandate Execution${usersMandateExecutionsGraphData[i].total > 1 ? 's' : ''}`
			}
			usersMandateExecutionsGraphData[i].totalValue = usersMandateExecutionsGraphData[i].total
		}
		setUsersMandateExecutionsGraphData(usersMandateExecutionsGraphData)
	}

	const getUserMandateExecutionsAmountGraphData = useRef(() => { })

	getUserMandateExecutionsAmountGraphData.current = async () => {
		var totalShownDays = 30
		var usersOnboardedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		// date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersMandateExecutionsAmountGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserMandateExecutionsAmountStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersOnboardedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersMandateExecutionsAmountGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserMandateExecutionsAmountStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersOnboardedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersMandateExecutionsAmountGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: (data["" + (i)]?.total) ?? 0,
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersMandateExecutionsAmountGraphData.length; i++) {
			if (i === 0) {
				minValue = usersMandateExecutionsAmountGraphData[i].total
			} else {
				if (usersMandateExecutionsAmountGraphData[i].total < minValue) {
					minValue = usersMandateExecutionsAmountGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersMandateExecutionsAmountGraphData.length; i++) {
			if (usersMandateExecutionsAmountGraphData[i].total === 0) {
				usersMandateExecutionsAmountGraphData[i].totalString = 'No Mandate Executions'
			}
			else {
				usersMandateExecutionsAmountGraphData[i].totalString = currencyFormat(usersMandateExecutionsAmountGraphData[i].total ?? 0)
			}
			usersMandateExecutionsAmountGraphData[i].totalValue = usersMandateExecutionsAmountGraphData[i].total
		}
		setUsersMandateExecutionsAmountGraphData(usersMandateExecutionsAmountGraphData)
	}

	const getUserActiveGraphData = useRef(() => { })

	getUserActiveGraphData.current = async () => {
		var totalShownDays = 30
		var usersActiveData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersActiveGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserActiveStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersActiveData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersActiveGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserActiveStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersActiveData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersActiveGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersActiveGraphData.length; i++) {
			if (i === 0) {
				minValue = usersActiveGraphData[i].total
			} else {
				if (usersActiveGraphData[i].total < minValue) {
					minValue = usersActiveGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersActiveGraphData.length; i++) {
			if (usersActiveGraphData[i].total === 0) {
				usersActiveGraphData[i].totalString = 'No Active Users'
			}
			else {
				usersActiveGraphData[i].totalString = `${usersActiveGraphData[i].total} Active User${usersActiveGraphData[i].total > 1 ? 's' : ''}`
			}
			usersActiveGraphData[i].totalValue = usersActiveGraphData[i].total
		}
		setUsersActiveGraphData(usersActiveGraphData)
	}

	const getUserYesbankMandateGraphData = useRef(() => { })

	getUserYesbankMandateGraphData.current = async () => {
		var totalShownDays = 30
		var usersPausedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersYesbankMandateGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserYesbankMandateStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersPausedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersYesbankMandateGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
							lendbox: (data["" + (i)]?.lendbox) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserYesbankMandateStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersPausedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersYesbankMandateGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0,
						lendbox: (data["" + (i)]?.lendbox) ?? 0
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersYesbankMandateGraphData.length; i++) {
			if (i === 0) {
				minValue = usersYesbankMandateGraphData[i].total
			} else {
				if (usersYesbankMandateGraphData[i].total < minValue) {
					minValue = usersYesbankMandateGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersYesbankMandateGraphData.length; i++) {
			if (usersYesbankMandateGraphData[i].total === 0) {
				usersYesbankMandateGraphData[i].totalString = 'No Yesbank Mandate Completed Users'
			}
			else {
				usersYesbankMandateGraphData[i].totalString = `${usersYesbankMandateGraphData[i].total} Yesbank Mandate Completed User${usersYesbankMandateGraphData[i].total > 1 ? 's' : ''}`
			}
			usersYesbankMandateGraphData[i].totalValue = usersYesbankMandateGraphData[i].total
			if (usersYesbankMandateGraphData[i].lendbox === 0) {
				usersYesbankMandateGraphData[i].lendboxString = 'No Yesbank Mandate Completed Users'
			}
			else {
				usersYesbankMandateGraphData[i].lendboxString = `${usersYesbankMandateGraphData[i].lendbox} Yesbank Mandate Completed User${usersYesbankMandateGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersYesbankMandateGraphData[i].lendboxValue = usersYesbankMandateGraphData[i].lendbox
		}
		setUsersYesbankMandateGraphData(usersYesbankMandateGraphData)
	}

	const getUserRoundupGraphData = useRef(() => { })

	getUserRoundupGraphData.current = async () => {
		var totalShownDays = Math.min(Math.ceil((Date.now() - new Date(2022, 5, 16).valueOf()) / (1000 * 60 * 60 * 24)), 30)
		var usersPausedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersRoundUpGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserRoundUpStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersPausedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersRoundUpGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
							lendbox: (data["" + (i)]?.lendbox) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserRoundUpStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersPausedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersRoundUpGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0,
						lendbox: (data["" + (i)]?.lendbox) ?? 0
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersRoundUpGraphData.length; i++) {
			if (i === 0) {
				minValue = usersRoundUpGraphData[i].total
			} else {
				if (usersRoundUpGraphData[i].total < minValue) {
					minValue = usersRoundUpGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersRoundUpGraphData.length; i++) {
			if (usersRoundUpGraphData[i].total === 0) {
				usersRoundUpGraphData[i].totalString = 'No Active Round-Up Users'
			}
			else {
				usersRoundUpGraphData[i].totalString = `${usersRoundUpGraphData[i].total} Active Round-Up User${usersRoundUpGraphData[i].total > 1 ? 's' : ''}`
			}
			usersRoundUpGraphData[i].totalValue = usersRoundUpGraphData[i].total
			if (usersRoundUpGraphData[i].lendbox === 0) {
				usersRoundUpGraphData[i].lendboxString = 'No Active Round-Up Users'
			}
			else {
				usersRoundUpGraphData[i].lendboxString = `${usersRoundUpGraphData[i].lendbox} Active Round-Up User${usersRoundUpGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersRoundUpGraphData[i].lendboxValue = usersRoundUpGraphData[i].lendbox
		}
		setUsersRoundUpGraphData(usersRoundUpGraphData)
	}

	const getUserDailyDepositGraphData = useRef(() => { })

	getUserDailyDepositGraphData.current = async () => {
		var totalShownDays = Math.min(Math.ceil((Date.now() - new Date(2022, 5, 16).valueOf()) / (1000 * 60 * 60 * 24)), 30)
		var usersPausedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersDailyDepositGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserDailyDepositStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersPausedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersDailyDepositGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
							lendbox: (data["" + (i)]?.lendbox) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserDailyDepositStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersPausedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersDailyDepositGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0,
						lendbox: (data["" + (i)]?.lendbox) ?? 0
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersDailyDepositGraphData.length; i++) {
			if (i === 0) {
				minValue = usersDailyDepositGraphData[i].total
			} else {
				if (usersDailyDepositGraphData[i].total < minValue) {
					minValue = usersDailyDepositGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersDailyDepositGraphData.length; i++) {
			if (usersDailyDepositGraphData[i].total === 0) {
				usersDailyDepositGraphData[i].totalString = 'No Active Daily Deposit Users'
			}
			else {
				usersDailyDepositGraphData[i].totalString = `${usersDailyDepositGraphData[i].total} Active Daily Deposit User${usersDailyDepositGraphData[i].total > 1 ? 's' : ''}`
			}
			usersDailyDepositGraphData[i].totalValue = usersDailyDepositGraphData[i].total
			if (usersDailyDepositGraphData[i].lendbox === 0) {
				usersDailyDepositGraphData[i].lendboxString = 'No Active Daily Deposit Users'
			}
			else {
				usersDailyDepositGraphData[i].lendboxString = `${usersDailyDepositGraphData[i].lendbox} Active Daily Deposit User${usersDailyDepositGraphData[i].lendbox > 1 ? 's' : ''}`
			}
			usersDailyDepositGraphData[i].lendboxValue = usersDailyDepositGraphData[i].lendbox
		}
		setUsersDailyDepositGraphData(usersDailyDepositGraphData)
	}

	const getUserContactGraphData = useRef(() => { })

	getUserContactGraphData.current = async () => {
		var totalShownDays = Math.min(Math.ceil((Date.now() - new Date(2022, 6, 22).valueOf()) / (1000 * 60 * 60 * 24)), 30)
		var usersPausedData: any = {}
		var now = Date.now()
		var date = new Date(now)
		date.setDate(date.getDate() - 1)
		var month = "" + (date.getMonth() + 1)
		var day = date.getDate()
		var year = "" + date.getFullYear()
		date.setDate(date.getDate() - totalShownDays + 1)
		var nMonth = "" + (date.getMonth() + 1)
		var nDay = date.getDate()
		var nYear = "" + date.getFullYear()
		var totalDays = new Date(date.getFullYear(), (date.getMonth() + 1), 0).getDate();
		var usersContactGraphData: any[] = []
		while (nMonth !== month || nYear !== year) {
			let tempMonth = nMonth
			let tempDay = nDay
			let tempYear = nYear
			let tempTotalDays = totalDays
			await statsController.getUserContactStatsData(tempMonth, tempYear)
				.then(data => {
					for (var i = tempDay; i <= tempTotalDays; i++) {
						usersPausedData[i + "/" + tempMonth] = (data["" + (i)]?.total) ?? 0
						usersContactGraphData.push({
							date: i,
							showDate: i + "/" + tempMonth + "/" + tempYear,
							total: (data["" + (i)]?.total) ?? 0,
							email: (data["" + (i)]?.email) ?? 0,
							phone: (data["" + (i)]?.phone) ?? 0
						})
					}
				});
			nMonth = "" + (parseInt(nMonth) + 1)
			nDay = 1
			if (parseInt(nMonth) > 12) {
				nMonth = "" + 1
				nYear = "" + (parseInt(nYear) + 1)
			}
			totalDays = new Date(parseInt(nYear), parseInt(nMonth), 0).getDate()
		}
		await statsController.getUserContactStatsData(month, year)
			.then(data => {
				for (var i = Math.max(day - totalShownDays, 1); i <= day; i++) {
					usersPausedData[i + "/" + month + "/" + year] = (data["" + (i)]?.total) ?? 0
					usersContactGraphData.push({
						date: i,
						showDate: i + "/" + month + "/" + year,
						total: data["" + (i)]?.total ?? 0,
						email: (data["" + (i)]?.email) ?? 0,
						phone: (data["" + (i)]?.phone) ?? 0
					})
				}
			});
		var minValue = null
		for (var i = 0; i < usersContactGraphData.length; i++) {
			if (i === 0) {
				minValue = usersContactGraphData[i].total
			} else {
				if (usersContactGraphData[i].total < minValue) {
					minValue = usersContactGraphData[i].total
				}
			}
		}
		for (i = 0; i < usersContactGraphData.length; i++) {
			if (usersContactGraphData[i].total === 0) {
				usersContactGraphData[i].totalString = 'No Users'
			}
			else {
				usersContactGraphData[i].totalString = `${usersContactGraphData[i].total} User${usersContactGraphData[i].total > 1 ? 's' : ''} with both Email and Phone`
			}
			usersContactGraphData[i].totalValue = usersContactGraphData[i].total
			if (usersContactGraphData[i].email === 0) {
				usersContactGraphData[i].emailString = 'No Users with Phone'
			}
			else {
				usersContactGraphData[i].emailString = `${usersContactGraphData[i].email} User${usersContactGraphData[i].email > 1 ? 's' : ''} with both Phone`
			}
			usersContactGraphData[i].emailValue = usersContactGraphData[i].email
			if (usersContactGraphData[i].phone === 0) {
				usersContactGraphData[i].phoneString = 'No Users with Phone'
			}
			else {
				usersContactGraphData[i].phoneString = `${usersContactGraphData[i].phone} User${usersContactGraphData[i].phone > 1 ? 's' : ''} with both Phone`
			}
			usersContactGraphData[i].phoneValue = usersContactGraphData[i].phone
		}
		setUsersContactGraphData(usersContactGraphData)
	}

	return <>
		<Grid container spacing={3}>
			<Grid item xs={12} sm={11} >
				<Typography variant="h3">
					Stats
				</Typography>
			</Grid>
			<Grid item xs={12} sm={1} direction="row-reverse">
				<IconButton onClick={getData} size="large">
					<RefreshIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12} sm={12} >
				<Typography variant="h5">
					Users
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Card elevation={0} className={classes.mainCard} >
					<CardHeader
						className={classes.total}
						subheader="Total"
						title={`${usersStats?.total ?? 0} (+${usersTotalGraphData?.[usersTotalGraphData.length - 1]?.totalValue ?? 0} today)`}
					/>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<Card elevation={0} className={classes.card} >
									<CardHeader
										subheader="Daily (Last 30 days)"
										action={
											< ButtonGroup variant="text" size="large" >
												<Button
													className={classes.total}
													onClick={() => userTotalGraphRef.current.setLineGraph()}>
													<Icon>
														<ShowChartIcon />
													</Icon>
												</Button>
												<Button
													className={classes.total}
													onClick={() => userTotalGraphRef.current.setBarGraph()}
												>
													<Icon >
														<BarChartIcon />
													</Icon>
												</Button>
											</ButtonGroup>
										}
									/>
									<CardContent>
										<UserDailyGraph ref={userTotalGraphRef} data={usersTotalGraphData} color={Colors.green} />
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Card elevation={0} className={classes.card}								>
									<CardHeader
										subheader="Monthly"
										action={
											< ButtonGroup variant="text" size="large" >
												<Button
													className={classes.total}
													onClick={() => userTotalMonthlyGraphRef.current.setLineGraph()}>
													<Icon>
														<ShowChartIcon />
													</Icon>
												</Button>
												<Button
													className={classes.total}
													onClick={() => userTotalMonthlyGraphRef.current.setBarGraph()}
												>
													<Icon >
														<BarChartIcon />
													</Icon>
												</Button>
											</ButtonGroup>
										}
									/>
									<CardContent>
										<UserStatGraph ref={userTotalMonthlyGraphRef} data={userMonthlyData} dataKey='total' color={Colors.green} />
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={12}>
				<Card elevation={0} className={classes.mainCard} >
					<CardHeader
						className={classes.kyc}
						subheader="Lendbox"
						title={`${usersStats?.lendbox ?? 0} (+${usersLendboxGraphData?.[usersLendboxGraphData.length - 1]?.totalValue ?? 0} today)`}
					/>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<Card elevation={0} className={classes.card} >
									<CardHeader
										subheader="Daily (Last 30 days)"
										action={
											< ButtonGroup variant="text" size="large" >
												<Button
													className={classes.kyc}
													onClick={() => userLendboxGraphRef.current.setLineGraph()}>
													<Icon>
														<ShowChartIcon />
													</Icon>
												</Button>
												<Button
													className={classes.kyc}
													onClick={() => userLendboxGraphRef.current.setBarGraph()}
												>
													<Icon >
														<BarChartIcon />
													</Icon>
												</Button>
											</ButtonGroup>
										}
									/>
									<CardContent>
										<UserDailyGraph ref={userLendboxGraphRef} data={usersLendboxGraphData} color={Colors.orange} />
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Card elevation={0} className={classes.card}								>
									<CardHeader
										subheader="Monthly"
										action={
											< ButtonGroup variant="text" size="large" >
												<Button
													className={classes.kyc}
													onClick={() => userLendboxMonthlyGraphRef.current.setLineGraph()}>
													<Icon>
														<ShowChartIcon />
													</Icon>
												</Button>
												<Button
													className={classes.kyc}
													onClick={() => userLendboxMonthlyGraphRef.current.setBarGraph()}
												>
													<Icon >
														<BarChartIcon />
													</Icon>
												</Button>
											</ButtonGroup>
										}
									/>
									<CardContent>
										<UserStatGraph ref={userLendboxMonthlyGraphRef} data={userMonthlyData} dataKey='lendbox' color={Colors.orange} />
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Active"
						title={`${usersStats?.active ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersActiveGraphData} color={Colors.lightBlue} dataKey={'total'} />
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Lendbox Onboarded"
						title={`${usersStats?.onboarded?.lendbox ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersOnboardedGraphData} color={Colors.lightBlue} dataKey={'lendbox'} />
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Lendbox Onboarded Paused"
						title={`${usersStats?.paused?.lendbox ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersPausedGraphData} color={Colors.lightBlue} dataKey={'lendbox'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="All Paused"
						title={`${usersStats?.paused?.total ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersPausedGraphData} color={Colors.lightBlue} dataKey={'total'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Active Round-Up"
						title={`${usersStats?.roundup?.lendbox ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersRoundUpGraphData} color={Colors.lightBlue} dataKey={'lendbox'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Active Daily Deposit"
						title={`${usersStats?.dailyDeposit?.lendbox ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersDailyDepositGraphData} color={Colors.lightBlue} dataKey={'lendbox'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Yesbank Mandate with lendbox KYC"
						title={`${usersStats?.yesbankMandate?.lendbox ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersYesbankMandateGraphData} color={Colors.lightBlue} dataKey={'lendbox'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Total Yesbank Mandates"
						title={`${usersStats?.yesbankMandate?.total ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersYesbankMandateGraphData} color={Colors.lightBlue} dataKey={'total'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Yesbank Mandates Executed"
						title={`${usersStats?.yesbankMandateExecution ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsBarGraph data={usersMandateExecutionsGraphData} color={Colors.lightBlue} dataKey={'total'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Yesbank Mandates Executed Amount"
						title={currencyFormat(usersStats?.yesbankMandateExecutionAmount ?? 0)}
					/>
					<CardContent className={classes.cardContent}>
						< StatsBarGraph data={usersMandateExecutionsAmountGraphData} color={Colors.lightBlue} dataKey={'total'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Total Users with Email"
						title={`${usersStats?.contact?.email ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersContactGraphData} color={Colors.lightBlue} dataKey={'email'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Total Users with Phone"
						title={`${usersStats?.contact?.phone ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersContactGraphData} color={Colors.lightBlue} dataKey={'phone'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={classes.card}>
					<CardHeader
						className={classes.active}
						subheader="Total Users with both Email and Phone"
						title={`${usersStats?.contact?.total ?? 0}`}
					/>
					<CardContent className={classes.cardContent}>
						< StatsAreaGraph data={usersContactGraphData} color={Colors.lightBlue} dataKey={'total'} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={12} >
				<Typography variant="h5">
					Investment
				</Typography>
			</Grid>
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'balance'} subheader={'AUM'} title={`${currencyFormat((investmentStatsData?.lendbox?.balance ?? 0))}`} />
			{/* <InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'totalSaved'} subheader={'Total Saved'} title={`${currencyFormat((investmentStatsData?.lendbox?.totalSaved ?? 0))}`} /> */}
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'invested'} subheader={'Invested'} title={`${currencyFormat((investmentStatsData?.lendbox?.invested ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'inProgress'} subheader={'In Progress'} title={`${currencyFormat((investmentStatsData?.lendbox?.inProgress ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'totalWithdrawal'} subheader={'Total Withdrawn'} title={`${currencyFormat((investmentStatsData?.lendbox?.totalWithdrawal ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'withdrawalRate'} subheader={'Withdrawal Rate'} title={`${(((investmentStatsData?.lendbox?.totalWithdrawal ?? 0) + investmentStatsData?.lendbox?.withdrawalInProgress ?? 0) / ((investmentStatsData?.lendbox?.balance ?? 1) + (investmentStatsData?.lendbox?.totalWithdrawal ?? 0)) * 100).toFixed(2)}%`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'directInvested'} subheader={'Directly Invested'} title={`${currencyFormat((investmentStatsData?.lendbox?.directInvested ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'dailyDeposit'} subheader={'Daily Deposit'} title={`${currencyFormat((investmentStatsData?.lendbox?.dailyDeposit ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'roundup'} subheader={'Roundup'} title={`${currencyFormat((investmentStatsData?.lendbox?.roundup ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'dailyDepositDays'} subheader={'Daily Deposit Days'} title={`${((investmentStatsData?.lendbox?.dailyDepositDays ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'roundupSpends'} subheader={'Roundup Spends'} title={`${((investmentStatsData?.lendbox?.roundupSpends ?? 0))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'dailyDepositAverage'} subheader={'Average Daily Deposit'} title={`${currencyFormat((investmentStatsData?.lendbox?.dailyDeposit ?? 0) / (investmentStatsData?.lendbox?.dailyDepositDays ?? 1))}`} />
			<InvestmentCard cardClass={classes.card} cardHeaderClass={classes.investments} color={Colors.primary} data={investmentGraphData} dataKey={'roundupAverage'} subheader={'Average Roundup'} title={`${currencyFormat((investmentStatsData?.lendbox?.roundup ?? 0) / (investmentStatsData?.lendbox?.roundupSpends ?? 1))}`} />
		</Grid >
	</>;
}