import { IconButton } from "@mui/material";
import { DataGrid, GridColumns, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import Layout from "components/Layout";
import React from "react";
import { useHistory } from "react-router";
import { useKeys } from "services/contexts/KeysContext";

const CustomToolbar = (props: any) => {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}

export default function Developer() {
	const { keys } = useKeys()
	const history = useHistory()

	const columns: GridColumns = [
		{ field: "id", headerName: "ID", width: 400 },
		{
			field: "data",
			headerName: "data",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/keysView/${params.id}`)
				};
				return (
					<IconButton onClick={onClick} title={"KeysView"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
	]
	return (
		<Layout id={1}>
			<div
				style={{
					height: "calc(100vh - 170px)",
					width: "100%"
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar
					}}
					rows={keys}
					columns={columns}
					loading={keys.length === 0} />
			</div>
		</Layout >
	)
}

