import { CardContent, CardHeader, Grid, Card } from "@mui/material";
import React from "react";
import StatsAreaGraph from "./mini-components/StatsAreaGraph";

export default function InvestmentCard(props: { data: any[], dataKey: string, cardClass: string, cardHeaderClass: string, color: string, title: string, subheader: string }) {
	var cardClass = props.cardClass
	var cardHeaderClass = props.cardHeaderClass
	var color = props.color
	var title = props.title
	var subheader = props.subheader
	var data = props.data
	var dataKey = props.dataKey


	return (
		<>
			<Grid item xs={12} sm={6}>
				<Card elevation={0} className={cardClass}>
					<CardHeader
						className={cardHeaderClass}
						title={title}
						subheader={subheader}
					/>
					<CardContent>
						<StatsAreaGraph color={color} data={data} dataKey={dataKey} />
					</CardContent></Card>
			</Grid>
		</>
	)
}
