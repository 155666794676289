import { CircularProgress } from "@mui/material";
import { Colors } from "models/Colors";
import React, { memo } from "react";
import { Bar, BarChart, Tooltip, XAxis } from "recharts";

const CustomTooltipContent = (props?: { active?: any, payload?: any, label?: any }) => {
	var active = props?.active
	if (active) {
		var payload = props?.payload[0]?.payload
		var dataKey = props?.payload[0]?.dataKey
		var viewString = payload[(dataKey?.substring(0, dataKey.length - 5) ?? '') + 'String'] ?? ''
		return (
			<div className="custom-tooltip" style={{ backgroundColor: Colors.background }} >
				<p className="label">{`${payload?.showDate}`}</p>
				<p className="intro">{`${viewString}`}</p>
			</div>
		);
	}
	return null;
};

function StatsBarGraph(props: { data: any[], color: string, dataKey: string }) {
	var data = props.data
	var color = props.color
	var dataKey = props.dataKey
	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			{(() => {
				if (data.length === 0) {
					return (
						<CircularProgress />
					)
				} else {
					var hasData = false
					for (var i in data) {
						if ((data[i]?.[dataKey] ?? 0) !== 0)
							hasData = true
					}
					if (hasData) {
						return (
							<BarChart
								width={500}
								height={260}
								data={data}>
								<Bar
									dataKey={dataKey + "Value"}
									fill={color}
									radius={25} />
								<XAxis dataKey="date" />
								<Tooltip content={<CustomTooltipContent />} cursor={{ fill: Colors.tooltipOverlay }} />
							</BarChart>
						)
					}
					else {
						return (
							<div>
								No Data
							</div>
						)
					}
				}
			})()
			}
		</div>
	)
}
export default memo(StatsBarGraph)