import { Button, Card, CssBaseline, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import logo from "assets/logo.png"
import Center from "components/Center"
import { Colors } from "models/Colors"
import React, { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "services/contexts/AuthContext"

const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: Colors.background,
	},
}))

export default function Login() {
	const { login } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const classes = useStyles();
	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			const { email, password } = event.target.elements;
			try {
				setError("")
				setLoading(true)
				await login(email.value, password.value)
				history.push("/")
			} catch (e) {
				setError("Failed to log in")
				alert(error)
			}

			setLoading(false)
		}, [error, history, login]
	)

	return (
		<div style={{
			backgroundColor: Colors.background,
			width: "100vw",
			height: "100vh",
			textAlign: "center",
		}}>
			<CssBaseline />
			<Center>
				<Card elevation={0} className={classes.card} style={{
					width: 400,
					padding: 20
				}} >
					<img src={logo} alt="Logo" height={400} />
					<Typography variant="h5">
						Log In
					</Typography>
					<form onSubmit={handleSubmit}>
						<div style={{
							padding: 10
						}} />
						<TextField id="email" type="email" label="Email" fullWidth required />
						<div style={{
							padding: 10
						}} />
						<TextField id="password" type="password" label="Password" fullWidth required />
						<div style={{
							padding: 10
						}} />
						<Button disabled={loading} type="submit" fullWidth={true}>Log In</Button >
					</form>
				</Card>
			</Center>
		</div>
	)
}
