import { CircularProgress } from "@mui/material";
import { Colors } from "models/Colors";
import React, { memo } from "react";
import { Line, LineChart, Tooltip, XAxis } from "recharts";

const CustomTooltipContent = (props?: { active?: any, payload?: any, label?: any }) => {
	var active = props?.active ?? false
	if (active) {
		var payload = props?.payload[0]?.payload
		var dataKey = props?.payload[0]?.dataKey
		var viewString = payload[dataKey + 'String'] ?? ''
		return (
			<div className="custom-tooltip" style={{ backgroundColor: Colors.background }} >
				<p className="label">{`${payload?.showDate}`}</p>
				<p className="intro">{`${viewString}`}</p>
			</div>
		);
	}
	return null;
};

function StatsLineGraph(props: { data: any[], color: string, dataKey: string }) {
	var data = props.data
	var color = props.color
	var dataKey = props.dataKey
	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			{(() => {
				if (data.length === 0) {
					return (
						<CircularProgress />
					)
				} else {
					return (
						<LineChart
							width={500}
							height={260}
							data={data}>
							<Line
								type="monotone"
								dataKey={dataKey}
								stroke={color}
								fill={color}
								radius={25}
							/>
							<XAxis dataKey="date" />
							<Tooltip content={<CustomTooltipContent />} cursor={{ fill: Colors.tooltipOverlay }} />
						</LineChart>
					)
				}
			})()
			}
		</div>
	)
}
export default memo(StatsLineGraph)