import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/remote-config";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getRemoteConfig, fetchAndActivate, ensureInitialized } from "firebase/remote-config";
import { initializeApp } from 'firebase/app';


var app = initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

export const auth = getAuth(app)
export const db = getFirestore(app);
export const FieldValue = firebase.firestore.FieldValue
export const rc = getRemoteConfig(app)
rc.settings = {
	fetchTimeoutMillis: 60000,
	minimumFetchIntervalMillis: 0,
};

rc.defaultConfig = ({
	'transactionTypeClassificationDirect': JSON.stringify({
		'Bills': {
			'VPA "billdesk.electricity@icici"': 'Electricity Bill',
			'Google workspace': 'Google workspace',
			'GOOGLEWORKSP': 'Google workspace',
			'billdesk.tata-sky@icici': 'Tata Sky'
		},
		'Dining': {
			'Zomato Ltd.': 'Zomato',
			'Zomato media Private L...': 'Zomato',
		},
		'Leisure': {
			'GOOGLE *YouTubePremium': 'Youtube Premium',
			'Zomato media Private L...': 'Zomato',
			'Swiggy': 'Swiggy',
			'PAY*NETFLIX ENT': 'Netflix',
			'Spotify India LLP': 'Spotify',
			'Spotify I/HDFC BANK.': 'Spotify',
			'PLAYSTATION': 'PlayStation',
		},
		'Misc': { 'B U BHAND': 'B.U. Bhandari' },
		'Shopping': {
			'AMAZON PAY INDIA PRIVA': 'Amazon',
			'AMAZON SE/HDFC BANK.': 'Amazon',
			'AMAZON/Axis Bank/Re.': 'Amazon',
		},
		'Unknown': {
			'AMAZON PAY INDIA PRIVA BANGALORE     IND': 'Amazon',
		}
	}),
	'transactionTypeClassificationContains': JSON.stringify({
		'Swiggy': ['swiggy'],
		'Zomato': ['zomato'],
		'Spotify': ['spotify'],
		'Amazon': ['amazon'],
		'Flipkart': ['flipkart'],
		'Netflix': ['netflix', 'netfl'],
		'Airtel': ['airtel'],
		'Dunzo': ['dunzo'],
		'Dorabjee': ['dorabjee'],
		'Zerodha': ['zerodha'],
		'First Cry': ['firstcry', 'first cry'],
		'The Souled Store': ['thesouledstore'],
		'Big Basket': ['bigbasket', 'big basket', 'bbstar'],
		'Ola': ['ola'],
		'Uber': ['uber'],
		'Starbucks': ['starbucks'],
		'Apple': ['apple'],
		'Bharat Petrol Pump': ['bharat petrol', 'bharatpetrol'],
		'Petrol Pump': ['petrol'],
		'Coffee Jar Cafe': ['coffee jar cafe'],
		'Capes India': ['capesindia', 'capes india'],
		'Urban Company': ['urban com', 'urbancom'],
		'PlayStation': ['playstation', 'play station'],
		'Youtube Premium': ['youtubepremium', 'youtube premium'],
		'B.U. Bhandari': ['b.u. bhandari'],
		'Google Workspace': ['googleworksp', 'google workspace'],
		'Oyo Rooms': ['oyo'],
		'IKEA': ['ikea'],
		'UPI Transaction': [
			'upi',
			'vpa',
			'@apl',
			'@abfspay',
			'@fbl',
			'@axisb',
			'@hdfcbankjd',
			'@myicici',
			'@ikwik',
			'@okbizaxis',
			'@okicici',
			'@ybl',
			'@hdfcbank',
			'@BARODAMPAY',
			'@rbl',
			'@allbank',
			'@aubank',
			'axisbank',
			'@bandhan',
			'@indus',
			'@kbl',
			'@federal',
			'@sbi',
			'@yesbank',
			'@citi',
			'@citigold',
			'@dbs',
			'@freecharge',
			'@okaxis',
			'@oksbi',
			'@hsbc',
			'@icici',
			'@kotak',
			'@paytm',
			'@axl',
			'@ibl',
			'@sib',
			'@pingpay',
			'@waaxis',
			'@wahdfcbank',
			'@wasbi',
		],
	}),
});
fetchAndActivate(rc)
ensureInitialized(rc)
export default app
