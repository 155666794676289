import { IconButton } from "@mui/material";
import { DataGrid, GridColumns, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import Layout from "components/Layout";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import TransactionController, { TransactionMonth } from "services/controllers/TransactionController";
import { Colors } from "models/Colors";

const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}
export default function TransactionsMonth(props: { match: { params: { uid: string } } }) {
	const [transactionMonthList, setTransactionMonthList] = useState<TransactionMonth[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const transactionController = new TransactionController()
	const history = useHistory()

	const uid: string = props.match.params.uid
	useEffect(() => {
		const fetchData = () => {
			getTransactionMonths.current()
		}
		return fetchData()
	}, [])

	const columns: GridColumns = [
		{ field: "month", headerName: "Month", width: 300 },
		{
			field: "View",
			headerName: "View",
			width: 125,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push(`/users/${uid}/transaction/${params.id}`)
				};
				return (
					<IconButton onClick={onClick} title={"Goals"} size="large">
						<ArrowForwardIosIcon />
					</IconButton>
				);
			}
		},
	]

	const getTransactionMonths = useRef(() => { })

	getTransactionMonths.current = async () => {
		transactionController.getTransactionMonths(uid, (data) => {
			setTransactionMonthList(data)
			if (loading)
				setLoading(false)
		})
	}

	return (
		<Layout id={2}>
			<div
				style={{
					height: "calc(100vh - 170px)",
					width: "100%"
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar
					}}
					componentsProps={{
						toolbar: {
							goBackFunction: () => {
								history.push("/users")
							},
						}
					}}
					rows={transactionMonthList}
					columns={columns}
					loading={loading} />
			</div>
		</Layout>
	)
}
