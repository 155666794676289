import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "services/contexts/AuthContext";

export default function PrivateRoute({ ...routeProps }) {
	const { currentUser } = useAuth()
	if (currentUser) {
		return <Route {...routeProps} />;
	}
	else {
		return <Redirect to="/login" />
	}
}
