import React from "react"
import { Redirect, Route } from "react-router-dom"
import { useAuth } from "services/contexts/AuthContext"


export default function NotLoggedIn({ ...routeProps }) {
	const { currentUser } = useAuth()

	if (currentUser) {
		return <Redirect to="/" />
	}
	else {
		return <Route {...routeProps} />;
	}
}
