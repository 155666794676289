import { IconButton } from "@mui/material";
import { DataGrid, GridColumns, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";
import Layout from "components/Layout";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import TransactionController, { Transaction } from "services/controllers/TransactionController";
import { Colors } from "models/Colors";

const CustomToolbar = (props: any) => {
	const { goBackFunction } = props
	return (
		<GridToolbarContainer>
			<IconButton onClick={goBackFunction} size="large">
				<ArrowBackIosIcon style={{ color: Colors.primary }} />
			</IconButton>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}
export default function Transactions(props: { match: { params: { uid: string, month: string } } }) {
	const [transactionsList, setTransactionsList] = useState<Transaction[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const transactionController = new TransactionController()
	const history = useHistory()

	const uid: string = props.match.params.uid
	const month: string = props.match.params.month
	useEffect(() => {
		const fetchData = () => {
			getTransactions.current()
		}
		return fetchData()
	}, [])

	const columns: GridColumns = [
		{ field: "id", headerName: "Timestamp", width: 300 },
		{ field: "type", headerName: "Type", width: 150 },
		{ field: "amount", headerName: "Amount", width: 150 },
		{ field: "total", headerName: "Total", width: 150 },
		{ field: "status", headerName: "Status", width: 150 },
		{ field: "txnType", headerName: "Transaction Type", width: 150 },
		{ field: "accountType", headerName: "Account Type", width: 150 },
		{ field: "bankName", headerName: "Bank Name", width: 150 },
	]

	const getTransactions = useRef(() => { })

	getTransactions.current = async () => {
		transactionController.getTransactions(uid, month, (data) => {
			setTransactionsList(data)
			if (loading)
				setLoading(false)
		})
	}

	return (
		<Layout id={2}>
			<div
				style={{
					height: "calc(100vh - 170px)",
					width: "100%"
				}}>
				<DataGrid
					components={{
						Toolbar: CustomToolbar
					}}
					componentsProps={{
						toolbar: {
							goBackFunction: () => {
								history.push("/users/" + uid + "/transaction")
							},
						}
					}}
					rows={transactionsList}
					columns={columns}
					loading={loading} />
			</div>
		</Layout>
	)
}
